<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5 same_modal">
                <div class="brand-logo text-center">
                  <img src="@/assets/images/logo-footer.webp" style="width: 100px;">
                </div>
                <h4 class="text-center">Forgot Password ?</h4>
                <h6 class="font-weight-light text-center">Enter your email to reset your password.</h6>
                <form class="pt-3" @submit.prevent="onSubmit">
                  <div class="form-group" :class="{ error: v$.form1.email.$errors.length }">
                    <input type="email" class="form-control form-control-lg" id="exampleInputEmail1"
                      placeholder="Enter Email" v-model="v$.form1.email.$model" required>
                    <div class="input-errors" v-for="(error, index) of v$.form1.email.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="btn-group w-100">
                      <router-link to="/login" class="btn btn-info fs-6 fw-bolder">
                        Cancel
                      </router-link>
                      <!-- <button type="button" v-on:click="logIn()"
                      class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn believe-btn"
                      :disabled="v$.form.$invalid">SIGN IN</button> -->
                      <button type="button" id="kt_password_reset_submit" class="btn btn-lg same_btn fw-bolder"
                        v-on:click="forgot()" :disabled="v$.form1.$invalid">
                        <span class="indicator-label">Submit</span>
                      </button>
                    </div>
                  </div>
                  <!-- <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input">
                        Keep me signed in
                        <i class="input-helper"></i>
                      </label>
                    </div>
                    <a href="javascript:void(0);" class="auth-link text-black">Forgot password?</a>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
    <vue-snotify></vue-snotify>
  </section>

</template>
<script>
import api from "../../config/api.js";
//import { ref } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  name: "ForgetPassword",

  data() {
    return {
      email: "",
      error: null,

      form1: {
        email: "",
      },
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      form1: {
        email: {
          required,
          email,
        },
      },
    };
  },

  methods: {
    async forgot() {
      try {
        this.error = null;
        let result = await api.post(`/admin/forgot-password`, {
          email: this.form1.email,
        });
        localStorage.setItem("loginkey", result.data.message);
        // this.$toast.success(result.data.message + "Please Enter Verify Code");
        this.$swal('Success!', result.data.message + "Please Enter Verify Code", 'success')
        this.$store.commit("getemail", this.form1.email);
        this.$router.push('/login');
      } catch (error) {
        this.error = error.response.data.message;
        // this.$toast.error(this.error);
        this.$swal('Error!', this.error, 'error')
      }
    },
  },
};
</script>

<style scoped>
</style>
